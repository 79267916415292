<script lang="ts" setup>
import { VueFinalModal } from 'vue-final-modal'
import { DocumentType } from '@register'
import { computed, ref } from 'vue'
interface Props {
  defaultType: DocumentType
  documentId: string
}
const props = defineProps<Props>()

const emit = defineEmits<{
  (e: 'close'): void
  (e: 'confirm', type: DocumentType): void
}>()

const route = useRoute('division-companyId-workflow')
const documentURI = computed(() => {
  const apiURL = useRuntimeConfig().public.apiURL
  return `${apiURL}/companies/${route.params.companyId}/importDocuments/${props.documentId}/pdf`
})
const documentType = ref(props.defaultType)

const options = Object.values(DocumentType)
  .map((value) => ({
    label: title(value),
    value,
  }))
  .filter((option) => option.value !== DocumentType.UNKNOWN)

const onConfirm = () => {
  emit('confirm', documentType.value)
}
</script>
<template>
  <VueFinalModal
    class="flex justify-center bg-black/60 transition-all"
    content-class="w-full"
    overlay-transition="vfm-fade"
  >
    <ModalContainer
      title="Update Document Type"
      close-on-click
      class="h-screen"
      container-class="w-[95vw] md:w-[75vw] max-w-[80vw]"
      @close="() => emit('close')"
    >
      <div class="grid grid-cols-2 gap-8">
        <div class="border-2 border-gray-700 px-0">
          <LazyWebViewer
            :initial-doc="documentURI"
            :options="{ enableAnnotations: false }"
            class="h-[calc(100vh-200px)] min-h-[500px]"
          />
        </div>
        <div class="">
          <FormKit
            v-model="documentType"
            type="listbox"
            track-by="value"
            label-by="label"
            label="Select the new document type"
            :options="options"
            :searchable="true"
          />
          <div class="mt-10 flex justify-end gap-2">
            <Button color="transparentSecondary" @click="() => emit('close')">
              Cancel
            </Button>
            <Button @click="onConfirm">Confirm</Button>
          </div>
        </div>
      </div>
    </ModalContainer>
  </VueFinalModal>
</template>
